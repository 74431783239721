import { Directive, ElementRef, HostListener } from '@angular/core';
import { timer } from 'rxjs';

@Directive({
  selector: '[focusout]',
})
export class FocusOut {
  constructor(public elementRef: ElementRef) {}

  @HostListener('keydown.enter', ['$event'])
  onEnter(): void {
    timer(0).subscribe(() => {
      this.elementRef.nativeElement.blur();
    });
  }
}
