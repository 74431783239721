import {Injectable} from '@angular/core';
import {convertMillisecondsToHoursMinutes} from 'src/app/utilities/convert-time';
import {TotalTimeTrackedByDay} from "./time-entries-calculator.service";
import {inverseFormattedDay} from "../../utilities/inverse-formatted-day";
import {FormattedDate} from "../../utilities/format-date";

@Injectable({
  providedIn: 'root',
})
export class TotalTimesCalculatorService {
  addAbsences(totalTimesTrackedByDay: TotalTimeTrackedByDay[], absences: Record<FormattedDate, number>) {
    const result = [...totalTimesTrackedByDay];

    for (const totalTimeTrackedByDay of result) {
      for (const absence in absences) {
        const dayOfCurrentAbsence = inverseFormattedDay(absence).getDay();
        if (dayOfCurrentAbsence === totalTimeTrackedByDay.day) {
          result[dayOfCurrentAbsence - 1].total +=
            absences[absence as FormattedDate] * 3600000;
          result[dayOfCurrentAbsence - 1].timeConverted =
            convertMillisecondsToHoursMinutes(
              result[dayOfCurrentAbsence - 1].total,
            );
        }
      }
    }

    return result;
  }
}
