export const environment = {
  sentryDsn: 'https://14dc111de3af4810a0b8ce9560981b1a@sentry.monade.io/57',
  apiUrl: 'https://gyca5lcsn8.execute-api.eu-central-1.amazonaws.com/prod',
  baseFrontendUrl: 'https://timesheets.monadeapps.xyz',
  oauth: {
    url: 'auth.monade.io',
    poolId: 'eu-central-1_t1i24ScEg',
    clientId: '44l32m4suq5cfcrsto9vfsgchq',
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
  },
};
