import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appIgnoreEscapeValue]',
})
export class IgnoreEscapedValue {
  private originalValue!: string;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private ngControl: NgControl,
  ) {}

  @HostListener('focus')
  onFocus(): void {
    this.originalValue = this.elementRef.nativeElement.value;
  }

  @HostListener('keydown.escape', ['$event'])
  onEscapeKeyDown(): void {
    this.elementRef.nativeElement.value = this.originalValue;
    const ctrl = this.ngControl.control;
    ctrl?.setValue(this.originalValue);
  }
}
