import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  from,
  map,
  mergeMap,
  forkJoin,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { FavouriteTask } from '../models/favourite-task.interface';
import JsFileDownloader from 'js-file-downloader';
import { User } from '../models/user.interface';
import { CachingService } from './caching.service';
import { Task } from '../models/task.interface';
import { Absence, AbsenceDTO } from '../models/absence.interface';
import { TimeEntriesByTaskId } from '../models/time-entries-by-task-id.interface';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClickupService {
  currentUser$ = new BehaviorSubject<User | null>(null);

  private readonly CLICKUP_ENDPOINT = environment.apiUrl;

  constructor(
    private http: HttpClient,

    private authService: AuthService,
    private cacheService: CachingService,
  ) {}

  getUsers() {
    const endpoint = `${this.CLICKUP_ENDPOINT}/users`;
    return this.cacheService.httpRequest<User[]>(endpoint);
  }

  getUserInfo() {
    if (this.currentUser$.value) {
      return this.currentUser$.asObservable();
    }

    return forkJoin({
      userData: this.authService.getUserData(),
      users: this.getUsers(),
    }).pipe(
      map(({ userData, users }) => {
        const email =
          userData.UserAttributes.find((e) => e.Name === 'email')?.Value ??
          null;
        const user = users.find((user) => user.email === email) ?? null;
        if (user) {
          this.currentUser$.next(user);
        }
        return user;
      }),
    );
  }

  getTasks(start: number, end: number) {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }
    const endpoint = `${this.CLICKUP_ENDPOINT}/tasks?user_id=${user.id}&role=${user.role}&start_date=${start}&end_date=${end}`;
    return this.cacheService.httpRequest<Task[]>(endpoint);
  }

  getTimeClickUp(start: number, end: number) {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }
    const endpoint = `${this.CLICKUP_ENDPOINT}/time?user=${user.id}&start_date=${start}&end_date=${end}`;
    return this.cacheService.httpRequest<TimeEntriesByTaskId[]>(endpoint);
  }

  trackTime(duration: number, task_id: string, start_date: number) {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }

    const endpoint = `${this.CLICKUP_ENDPOINT}/track`;
    const body = {
      duration: duration,
      task_id: task_id,
      start_date: start_date,
      assignee: user.id,
    };

    return this.http.post<any>(endpoint, body);
  }

  createAbsence(absence: AbsenceDTO) {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }
    const data = {
      title: absence.title,
      tags: absence.type === 'vacation' ? [] : ['flexibility'],
      start_date: absence.startsAt.getTime().toString(),
      end_date: absence.endsAt.getTime().toString(),
      user: user.id,
    }
    return this.http.post<Task>(`${this.CLICKUP_ENDPOINT}/createAbsence`, data);
  }

  getAbsences(start: number, end: number) {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }

    const endpoint = `${this.CLICKUP_ENDPOINT}/absences?user=${user.id}&start_date=${start}&end_date=${end}`;
    return this.cacheService.httpRequest<Absence[]>(endpoint);
  }

  trackFavouriteTasks(favouriteTasks: FavouriteTask) {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }

    const endpoint = `${this.CLICKUP_ENDPOINT}/postFavouriteTasks`;

    return this.http.post<any>(endpoint, {
      userId: user.id,
      taskId: favouriteTasks.taskId,
    });
  }

  getFavouriteTasks() {
    const user = this.currentUser$.value;
    if (!user) {
      throw new Error('User not found');
    }
    const endpoint = `${
      this.CLICKUP_ENDPOINT
    }/getFavouriteTasks?user=${encodeURIComponent(user.id)}`;
    return this.cacheService.httpRequest<FavouriteTask[]>(endpoint);
  }

  downloadXlsUrl(start: number, end: number): string {
    return `${this.CLICKUP_ENDPOINT}/export?start_date=${start}&end_date=${end}`;
  }

  exportXls(
    start: number,
    end: number,
    monthSelected: string,
  ): Observable<void> {
    return this.authService.getAuthHeaders().pipe(
      mergeMap((header) => {
        if (!header) {
          throw new Error('Token not found');
        }
        const downloader = new JsFileDownloader({
          timeout: 120000,
          url: this.downloadXlsUrl(start, end),
          filename: `timesheet_export_${monthSelected}.xlsx`,
          method: 'GET',
          headers: [
            {
              name: header.key,
              value: header.value,
            },
            {
              name: 'Accept',
              value: 'application/octet-stream',
            },
          ],
        });
        return from(downloader);
      }),
    );
  }
}
