import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetCounterComponent } from './components/budget-counter/budget-counter.component';
import { TimeTrackerComponent } from './components/time-tracker/time-tracker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './components/table/table.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { CommonsModule } from '../commons/commons.module';
import { AbsenceModalFormComponent } from './components/absence-modal-form/absence-modal-form.component';
import { AbsencesRowComponent } from './components/absences-row/absences-row.component';
import { DailyTotalsRowComponent } from './components/daily-totals-row/daily-totals-row.component';
import { AbsenceSuccessModalComponent } from './components/absence-success-modal/absence-success-modal.component';

@NgModule({
  declarations: [
    BudgetCounterComponent,
    AbsenceModalFormComponent,
    AbsenceSuccessModalComponent,
    AbsencesRowComponent,
    DailyTotalsRowComponent,
    TimeTrackerComponent,
    TableComponent,
    TableRowComponent,
  ],
  imports: [CommonModule, FormsModule, CommonsModule, FormsModule, ReactiveFormsModule],
  exports: [
    TableComponent,
    TableRowComponent,
    BudgetCounterComponent,
    TimeTrackerComponent,
  ],
})
export class TimesheetsModule {}
