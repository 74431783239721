import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { convertHoursMinutesToMilliseconds } from 'src/app/utilities/time-conversion';

export type TimeToBeTracked = {
  trackedTime: number | null;
  trackedDay: number;
  taskId: string;
};

@Component({
  selector: 'app-time-tracker',
  template: `
    @if (trackedTime && !isInputClicked) {
      <p (click)="activateTrackTimeInput()" class="cursor-pointer">
        {{ trackedTime === '0' ? '-' : trackedTime }}
      </p>
    }
    @if (!trackedTime && !isInputClicked) {
      <p (click)="activateTrackTimeInput()" class="cursor-pointer">-</p>
    }
    @if (isInputClicked) {
      <input
        customAutofocus
        #timeTrack
        type="text"
        placeholder=""
        [(ngModel)]="currentTrackedTimeHoursMinutes"
        (focus)="select()"
        (blur)="deactivateTrackTimeInput()"
        class="w-24 focus:outline-none text-center"
        (keydown)="onKeyDown($event)"
        appIgnoreEscapeValue
        focusout
      />
    }
  `,
})
export class TimeTrackerComponent {
  @Input() taskId!: string;
  @Input() trackedTime: string | null = '';
  @Input() day!: number;

  @Output() trackingData = new EventEmitter<TimeToBeTracked>();

  @ViewChild('timeTrack') timeTrack!: ElementRef<HTMLInputElement>;

  isInputClicked: boolean = false;
  currentTrackedTimeHoursMinutes: string | null = '';
  currentTrackedTimeMilliseconds: number | null = 0;

  activateTrackTimeInput() {
    this.currentTrackedTimeHoursMinutes =
      this.trackedTime === '0' ? '' : this.trackedTime;

    this.currentTrackedTimeMilliseconds = convertHoursMinutesToMilliseconds(
      this.trackedTime,
    );

    this.isInputClicked = true;
  }

  select() {
    this.timeTrack.nativeElement.setSelectionRange(
      0,
      this.timeTrack.nativeElement.value.length,
    );
  }

  deactivateTrackTimeInput() {
    this.isInputClicked = false;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.isInputClicked = false;
    } else if (event.key === 'Enter') {
      const convertedValue =
        this.currentTrackedTimeHoursMinutes === null ||
        this.currentTrackedTimeHoursMinutes === undefined
          ? null
          : convertHoursMinutesToMilliseconds(
              this.currentTrackedTimeHoursMinutes,
            );
      if (convertedValue !== null && convertedValue <= 0) {
        this.isInputClicked = false;
      }

      const data: TimeToBeTracked = {
        trackedTime: convertedValue,
        trackedDay: this.day,
        taskId: this.taskId,
      };
      this.trackingData.emit(data);
    }
  }
}
