import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  HostBinding,
} from '@angular/core';
import { TimeEntryMapped } from 'src/app/models/time-entry-mapped';
import { convertMillisecondsToHoursMinutes } from 'src/app/utilities/convert-time';
import { FavouriteTask } from 'src/app/models/favourite-task.interface';
import { TimeEntry } from 'src/app/models/time-entry.interface';
import { TimeToBeTracked } from 'src/app/timesheets/components/time-tracker/time-tracker.component';
import { Task, TaskStatus } from 'src/app/models/task.interface';
import {format, isSameWeek} from "date-fns";
import {TableRow} from "../../../models/table-row";

export type TimeEntries = Record<string, TimeEntryMapped | null>;

const BASE_TR_CLASSES =
  'border-b-2 border-b-gray-200 hover:bg-gray-200';

@Component({
  selector: '[app-table-row]',
  template: `
    <th class="text-left align-middle p-5">
      <div class="flex gap-3">
        <app-favourite-star
          [isFavouriteTask]="row.isFavorite"
          (click)="setFavouriteTaks()"
        ></app-favourite-star>
        @if (folderName !== 'hidden') {
          <a [href]="url" target="_blank">{{ folderName }} > {{ task.name }}</a>
        } @else {
          <a [href]="url" target="_blank">{{ task.name }}</a>
        }
      </div>
    </th>
    @for (day of days; track day) {
      <td
        class="text-center align-middle p-4"
        [ngClass]="{ 'cursor-not-allowed': statusIsComplete }"
      >
        @if (!statusIsComplete) {
          <app-time-tracker
            [taskId]="task.id"
            [trackedTime]="timeEntries[day]?.timeTrackedConverted || ''"
            [day]="day"
            (trackingData)="trackTime.emit($event)"
          ></app-time-tracker>
        } @else {
          <p>{{ timeEntries[day]?.timeTrackedConverted || '-' }}</p>
        }
      </td>
    }
    <td class="text-center align-middle p-4">
      <p>{{ totalsByTask }}</p>
    </td>
    <td class="text-center align-middle p-4">
      @if (budget) {
        <app-budget-counter
          [budget]="budget"
          [consumedBudget]="consumedBudget"
        ></app-budget-counter>
      }
    </td>
  `,
})
export class TableRowComponent implements OnChanges {
  @Input() row!: TableRow;
  // @Input() status: TaskStatus = { status: '', color: '', type: 'custom' };

  private readonly DEFAULT_TIME_ENTRIES: TimeEntries = {
    1: { date: '', timeTrackedConverted: '', timeTracked: 0, dataNumber: 0 },
    2: { date: '', timeTrackedConverted: '', timeTracked: 0, dataNumber: 0 },
    3: { date: '', timeTrackedConverted: '', timeTracked: 0, dataNumber: 0 },
    4: { date: '', timeTrackedConverted: '', timeTracked: 0, dataNumber: 0 },
    5: { date: '', timeTrackedConverted: '', timeTracked: 0, dataNumber: 0 },
  };

  days: number[] = [1, 2, 3, 4, 5];
  totalsByTask: string = '0';
  budget: number | string | null = null;
  consumedBudget: number | string | null = null;

  get task() {
    return this.row.task;
  }

  get timeEntries() {
    return this.row.timeEntries;
  }

  @Output() trackTime = new EventEmitter<TimeToBeTracked>();
  @Output() toggleFavourite = new EventEmitter<FavouriteTask>();

  @HostBinding('class') cssClasses = BASE_TR_CLASSES;

  ngOnChanges() {
    this.budget = this.getBudget(this.task);
    this.consumedBudget = this.getConsumedBudget(this.task);

    this.cssClasses = BASE_TR_CLASSES;
    if (this.statusIsComplete) {
      this.cssClasses += 'cursor-not-allowed opacity-50';
    }
  }

  getBudget(task: Task) {
    if (task.time_estimate) {
      return task.time_estimate / 3600000;
    }
    return task.custom_fields?.find(
      (field) => field.name.indexOf('Budget') !== -1,
    )?.value;
  }

  getConsumedBudget(task: Task) {
    return (task.time_spent ?? 0) / 3600000;
  }

  get status() {
    return this.task.status;
  }

  get statusIsComplete() {
    return this.status.type === 'closed' || this.status.type === 'done';
  }

  get folderName() {
    return this.task.folder.name;
  }

  get url() {
    return `https://app.clickup.com/t/${this.task.id}`;
  }

  setFavouriteTaks() {
    const data = {
      taskId: this.task.id,
      userId: 'me', // FIXME: get the user id
    };
    this.toggleFavourite.emit(data);
  }
}
