import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-budget-counter',
  template: `
    <div [ngClass]="{ 'text-error': isExceeded }">
      <span>{{ format(consumedBudget) }}</span>
      @if (budget) {
        <span> / {{ format(budget) }}</span>
      }
    </div>
  `,
})
export class BudgetCounterComponent {
  @Input() budget!: number | string | null;
  @Input() consumedBudget!: number | string | null;

  get isExceeded() {
    if (this.budget === null) {
      return false;
    }
    return +(this.consumedBudget ?? 0) > +this.budget;
  }

  format(value: number | string | null) {
    return `${(+(value ?? 0)).toFixed(0)}h`;
  }
}
