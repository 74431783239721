import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sign-out',
  template: ` <div class="flex justify-center items-center h-screen">
    <div>
      <h1 class="pb-2">Signed out</h1>
      <h4 class="pb-4">You can close this window safely.</h4>
      <app-button [isPrimary]="true" [disabled]="false" (click)="signIn()"
        >Sign In Again
      </app-button>
    </div>
  </div>`,
})
export class SignOutComponent {
  constructor(private authService: AuthService) {}

  signIn() {
    this.authService.googleFederatedSignIn();
  }
}
