import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Observable, tap} from "rxjs";
import {TableRow} from "../../models/table-row";
import {Task} from "../../models/task.interface";
import {FavouriteTask} from "../../models/favourite-task.interface";
import {TimeEntriesCalculatorService} from "./time-entries-calculator.service";
import {FormattedDate} from "../../utilities/format-date";
import {TotalTimesCalculatorService} from "./total-times-calculator.service";
import {TimeToBeTracked} from "../components/time-tracker/time-tracker.component";
import {dateToClickupFormat} from "../../utilities/clickupFormatDate";
import {TimeEntriesByTaskId} from "../../models/time-entries-by-task-id.interface";
import {TimeEntry} from "../../models/time-entry.interface";
import {convertMillisecondsToHoursMinutes} from "../../utilities/convert-time";
import {TimeEntryMapped} from "../../models/time-entry-mapped";
import {TimeEntriesMapped} from "../../models/time-entries-mapped";
import * as fuzzysort from "fuzzysort";

@Injectable({
  providedIn: 'root',
})
export class TableStoreService {
  private timeEntriesCalculator = inject(TimeEntriesCalculatorService);
  private totalTimesCalculator = inject(TotalTimesCalculatorService);

  readonly datesSubject = new BehaviorSubject<FormattedDate[]>([]);
  dates$ = this.datesSubject.asObservable();

  readonly tasksSubject = new BehaviorSubject<Task[]>([]);
  tasks$ = this.tasksSubject.asObservable();

  readonly taskSearchTermSubject = new BehaviorSubject<string | null>(null);
  taskSearchTerm$ = this.taskSearchTermSubject.asObservable();

  readonly absencesSubject = new BehaviorSubject<Record<FormattedDate, number>>(
    {},
  );
  absences$ = this.absencesSubject.asObservable();

  readonly timeEntriesSubject = new BehaviorSubject<TimeEntriesByTaskId[]>([]);
  timeEntries$ = this.timeEntriesSubject.asObservable();

  readonly favouriteTasksSubject = new BehaviorSubject<FavouriteTask[]>([]);
  favouriteTasks$ = this.favouriteTasksSubject.asObservable();

  rows$: Observable<TableRow[]> = combineLatest([
    this.dates$,
    this.tasks$,
    this.timeEntries$,
    this.favouriteTasks$,
  ]).pipe(
    map(([dates, tasks, timeEntriesByTaskId, favourites]) => {
      return tasks.map((task) => {
        const timeEntry =
          timeEntriesByTaskId.find((value) => value.task_id === task.id)
            ?.time_entries || null;

        const timeEntryMapped = this.normalizeAndMapTimeEntry(timeEntry, dates);

        return {
          task,
          timeEntries: timeEntryMapped,
          total: timeEntry ? this.getTotal(timeEntryMapped) : '0',
          isFavorite: favourites.some(
            (favouriteTask) => favouriteTask.taskId === task.id,
          ),
        };
      });
    }),
  );

  filteredRows$ = combineLatest([this.rows$, this.taskSearchTerm$]).pipe(
    map(([rows, searchTerm]) =>
      (searchTerm?.length || 0) >= 1 ?
      fuzzysort
        .go(searchTerm || '', rows, {
          keys: [(r) => r.task.name + ' ' + r.task.folder.name],
          all: true,
          threshold: 0.3,
          scoreFn: (a) => a.score * (a.obj.isFavorite ? 1.5 : 1),
        })
        .map((result) => {
          return result.obj;
        }) :
      rows.sort((a, b) => {
          return this.getSortKey(a).localeCompare(this.getSortKey(b));
        })
      ),
  );

  totalTrackedTimeByDay$ = combineLatest([
    this.timeEntries$,
    this.absences$,
  ]).pipe(
    map(([timeEntries, absences]) => {
      let result =
        this.timeEntriesCalculator.computeTimeEntriesByDay(timeEntries);
      result = this.totalTimesCalculator.addAbsences(result, absences);

      return result;
    }),
  );

  private getSortKey(row: TableRow): string {
    const favouritePrefix = row.isFavorite ? 0 : 1;
    return row.task.folder.name === 'hidden'
      ? `${favouritePrefix}_${row.task.name}`
      : `${favouritePrefix}_${row.task.folder.name}_${row.task.name}`;
  }

  toggleFavoriteTask(favoriteTask: FavouriteTask) {
    const favouriteTasks = this.favouriteTasksSubject.value;

    const isTaskAlreadyFavourite = favouriteTasks.some(
      (favouriteTask) => favouriteTask.taskId === favoriteTask.taskId,
    );
    if (!isTaskAlreadyFavourite) {
      this.favouriteTasksSubject.next([...favouriteTasks, favoriteTask]);
    } else {
      this.favouriteTasksSubject.next(
        favouriteTasks.filter(
          (favouriteTask) => favouriteTask.taskId !== favoriteTask.taskId,
        ),
      );
    }
  }

  isTimeTrackAlreadyPresent(trackInfo: TimeToBeTracked, dayToTrack: Date) {
    const dateClickupFormat = dateToClickupFormat(dayToTrack);

    const task = this.tasksSubject.value.find((e) => e.id === trackInfo.taskId);
    const previouslyTrackedTime = task?.timeEntries?.[dateClickupFormat] ?? -1;

    return trackInfo.trackedTime === previouslyTrackedTime;
  }

  trackTime(trackInfo: TimeToBeTracked, dayToTrack: Date) {
    const dateClickupFormat = dateToClickupFormat(dayToTrack);

    const timeEntriesByTaskId = this.timeEntriesSubject.value.find(
      (t) => t.task_id === trackInfo.taskId,
    )!;

    const newTimeEntry = { [dateClickupFormat]: trackInfo.trackedTime! };
    this.timeEntriesSubject.next([
      ...this.timeEntriesSubject.value.filter(
        (t) => t.task_id !== trackInfo.taskId,
      ),
      {
        task_id: trackInfo.taskId,
        time_entries: {
          ...(timeEntriesByTaskId?.time_entries || {}),
          ...newTimeEntry,
        },
      },
    ]);
  }

  private normalizeAndMapTimeEntry(
    timeEntry: TimeEntry | null,
    weekDates: FormattedDate[],
  ) {
    const defaultTimeEntry = weekDates.reduce((acc, date) => {
      acc[date] = null;
      return acc;
    }, {} as TimeEntry);

    const normalizedTimeEntry = {
      ...defaultTimeEntry,
      ...(timeEntry || {}),
    };

    return this.mapTimeEntry(normalizedTimeEntry);
  }

  private mapTimeEntry(timeEntry: TimeEntry) {
    return Object.entries(timeEntry).reduce(
      (acc, [dateAsString, timeValue]) => {
        const date = new Date(dateAsString).getDay();

        acc[date] = {
          date: dateAsString,
          timeTrackedConverted: timeValue
            ? convertMillisecondsToHoursMinutes(timeValue)
            : '',
          timeTracked: timeValue || 0,
          dataNumber: date,
        };

        return acc;
      },
      {} as TimeEntriesMapped,
    );
  }

  getTotal(value: TimeEntriesMapped) {
    const times = Object.values(value).map((value) => {
      return value?.timeTracked ?? 0;
    });
    let totals: number = 0;
    if (times.length !== 0) {
      totals = times.reduce((acc, current) => acc + current);
    }
    if (totals) {
      return convertMillisecondsToHoursMinutes(totals);
    } else {
      return '0';
    }
  }
}
