import { Inject, Injectable, InjectionToken } from '@angular/core';
import { forkJoin, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Auth,
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { UserData } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';
import { Buffer } from 'buffer';

@Injectable()
export class AuthService {
  readonly authLogs$ = new ReplaySubject<{ event: string; data?: any }>();

  constructor() {
    Auth.configure({
      userPoolId: environment.oauth.poolId,
      userPoolWebClientId: environment.oauth.clientId,
      oauth: {
        domain: environment.oauth.url,
        scope: environment.oauth.scope,
        redirectSignIn: `${environment.baseFrontendUrl}/auth`,
        redirectSignOut: `${environment.baseFrontendUrl}/auth/sign_out`,
        responseType: 'code',
      },
    });

    Hub.listen('auth', ({ payload }) => {
      this.authLogs$.next(payload);
    });
  }

  getJWT() {
    return this.getCurrentUser().pipe(
      map((data) => data?.session?.getAccessToken()?.getJwtToken()),
    );
  }

  getDecodedJWT() {
    return this.getJWT().pipe(
      map((token) => {
        if (!token) {
          return null;
        }
        const tokenParts: string[] = token.split('.');
        if (tokenParts.length !== 3) {
          console.error('Invalid JWT Token');
          return null;
        }
        const tokenPayload = tokenParts[1];
        const decodedPayload = Buffer.from(tokenPayload, 'base64').toString(
          'utf-8',
        );
        const parsedPayload = JSON.parse(decodedPayload);
        return parsedPayload;
      }),
    );
  }

  getCurrentUser() {
    return forkJoin({
      user: from(
        Auth.currentAuthenticatedUser() as Promise<CognitoUser | null>,
      ),
      session: from(Auth.currentSession()),
    }).pipe(
      switchMap(({ user, session }) => {
        if (user !== null) {
          return of(session.isValid() ? { user, session } : null);
        }
        return of(null);
      }),
      catchError(() => of(null)),
    );
  }

  getAuthHeaders() {
    return this.getCurrentUser().pipe(
      map((authData) => {
        if (!authData) {
          return null;
        }
        return {
          key: 'Authorization',
          value: `Bearer ${authData.session.getIdToken().getJwtToken()}`,
        };
      }),
    );
  }
  getUserData() {
    return this.getCurrentUser().pipe(
      mergeMap((authData) => {
        return new Observable<UserData>((subscriber) => {
          if (!authData) {
            subscriber.error(new Error());
            subscriber.complete();
            return;
          }

          authData.user.getUserData((err, data) => {
            if (err) {
              subscriber.error(err);
              subscriber.complete();
              return;
            }
            subscriber.next(data);
            subscriber.complete();
          });
        });
      }),
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.getCurrentUser().pipe(map((user) => user !== null));
  }

  googleFederatedSignIn() {
    return from(
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      }),
    );
  }

  signIn(email: string, password: string) {
    return from(Auth.signIn(email, password));
  }

  signOut() {
    return from(Auth.signOut());
  }

  completeNewPassword(user: CognitoUser, password: string) {
    return from(Auth.completeNewPassword(user, password));
  }

  forgotPassword(username: string) {
    return from(Auth.forgotPassword(username));
  }

  forgotPasswordSubmit(username: string, code: string, password: string) {
    return from(Auth.forgotPasswordSubmit(username, code, password));
  }

  canActivateAuth(router: Router) {
    return this.isLoggedIn().pipe(
      catchError((err) => {
        return of(false);
      }),
      mergeMap((isLogged) => {
        if (isLogged) {
          return of(true);
        }
        this.googleFederatedSignIn().subscribe();
        // router.navigate(["/auth/login"]);
        return of(false);
      }),
    );
  }

  canActivateNotAuth(router: Router) {
    return this.isLoggedIn().pipe(
      catchError(() => of(false)),
      map((isLogged) => !isLogged),
      mergeMap((isNotLogged) => {
        if (isNotLogged) {
          return of(true);
        }
        router.navigate(['/']);
        return of(false);
      }),
    );
  }
}
