import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
    @if (animated) {
      <span
        class="loader align-middle"
        [style]="{ width: width, height: height }"
      ></span>
    } @else {
      <div class="bg-gray-900 w-32 h-fit rounded-lg p-3 text-center">
        <p class=" text-white">{{ message }}</p>
      </div>
    }
  `,
  styles: [
    `
      .loader {
        border: 2px solid currentColor;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  ],
})
export class SpinnerComponent {
  @Input() message: string = 'Saving...';
  @Input() animated: boolean = false;
  @Input() width = '20px';
  @Input() height = '20px';
}
