export function convertMillisecondsToHoursMinutes(time: number): string {
  let hours = Math.floor(time / 3600000);
  let hoursToMinutes = (time / 3600000) * 60;
  let minutes = Math.floor(hoursToMinutes % 60);
  isNaN(hours) ? (hours = 0) : hours;
  isNaN(minutes) ? (minutes = 0) : minutes;
  if (minutes === 0) {
    return hours === 0 ? `${hours}` : `${hours}h`;
  } else if (hours === 0) {
    return minutes === 0 ? `${minutes}` : `${minutes}m`;
  } else {
    return `${hours}h ${minutes}m`;
  }
}
