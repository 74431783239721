import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-favourite-star',
  template: ` <button [innerHTML]="starIcon"></button> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouriteStarComponent {
  @Input() isFavouriteTask: boolean = false;

  get starIcon(): string {
    return this.isFavouriteTask ? '&#9733;' : '&#9734;';
  }
}
