import { Component, Input, ViewChild } from '@angular/core';
import { format } from 'date-fns';
import { ModalComponent } from 'src/app/commons/components/modal/modal.component';
import { AbsenceDTO } from 'src/app/models/absence.interface';

@Component({
  selector: 'app-absence-success-modal',
  template: `
    <app-modal #modal>
      <div body>
        <p class="text-center text-success">
          Absence <a class="font-bold" target="_blank" [href]="url">"{{ absence?.title }}"</a> successfully created!
        </p>
        <p class="text-center">
          Now add your absence <strong>both</strong> to your calendar and JetHR.
        </p>
      </div>

      <div footer class="flex justify-between">
        <app-button (click)="addToCalendar()" [isPrimary]="true"
          >Add to calendar</app-button
        >
        @if (absence?.type === 'vacation') {
          <app-button (click)="addToJetHR()" [isPrimary]="true"
            >Add to JetHR</app-button
          >
        }
      </div>
    </app-modal>
  `,
})
export class AbsenceSuccessModalComponent {
  @ViewChild('modal') modal!: ModalComponent;

  absence: AbsenceDTO & { id: string } | null = null;

  open(absence: AbsenceDTO & { id: string }) {
    this.modal.open();
    this.absence = absence;
  }

  close() {
    this.modal.close();
    this.absence = null;
  }

  get url() {
    if (!this.absence) {
      return '';
    }
    return `https://app.clickup.com/t/${this.absence.id}`;
  }

  addToJetHR() {
    window.open('https://employee.jethr.com/', '_blank');
  }

  openTask() {
    window.open(this.url, '_blank');
  }

  addToCalendar() {
    if (!this.absence) {
      return;
    }
    const params = new URLSearchParams();
    params.append('action', 'TEMPLATE');
    params.append('text', this.absence.title);
    params.append(
      'dates',
      `${format(this.absence.startsAt, 'yyyyMMdd')}/${format(this.absence.endsAt, 'yyyyMMdd')}`,
    );
    params.append('ctz', 'Europe/Rome');
    const url = `https://calendar.google.com/calendar/render?${params.toString()}`;

    window.open(url, '_blank');
  }
}
