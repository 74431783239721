import { Component, Input, SimpleChanges } from '@angular/core';
import {
  TimeEntriesCalculatorService,
  TotalTimeTrackedByDay,
} from '../../services/time-entries-calculator.service';

@Component({
  selector: '[app-daily-totals-row]',
  template: `
    <th class="text-left align-middle p-4">
      <p>TOTALE:</p>
    </th>
    @for (item of totals; track item) {
      <th
        class="text-center align-middle p-4"
        [ngClass]="{
          'text-strawberry': item.total < MILLISECONDS_PER_WORKING_DAY,
          'text-success': item.total >= MILLISECONDS_PER_WORKING_DAY
        }"
      >
        <p>{{ item.timeConverted }} / 8h</p>
      </th>
    }
    <th class="text-center align-middle p-4 w-[8rem]">
      <p>{{ totalOfTotals }} / 40h</p>
    </th>
    <th class="text-center">-</th>
  `,
})
export class DailyTotalsRowComponent {
  @Input() totals: TotalTimeTrackedByDay[] = [];

  readonly MILLISECONDS_PER_WORKING_DAY = 28800000
  totalOfTotals: string = '0';

  constructor(private timeEntriesCalculator: TimeEntriesCalculatorService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('totals' in changes) {
      this.sumTotalOfTotals();
    }
  }

  sumTotalOfTotals() {
    this.totalOfTotals = this.timeEntriesCalculator.computeTotals(this.totals);
  }
}
