export function convertHoursMinutesToMilliseconds(
  value: string | null,
): number | null {
  if (!value) {
    return 0;
  }
  if (value.match(/^[\d]+([,.][\d]+)?$/i)) {
    const normalizeValue = value.replace(',', '.');
    return hoursToMill(parseFloat(normalizeValue), 0);
  }
  const regexHours = /([0-9]|[1-9][0-9])(?=[h])/i;
  const regexMinutes = /([1-9]|[1-5][0-9])(?=[m])/i;
  const matchHours = value.match(regexHours);
  const hours = matchHours ? parseInt(matchHours[0]) : 0;
  const matchMinutes = value.match(regexMinutes);
  const minutes = matchMinutes ? parseInt(matchMinutes[0]) : 0;

  if (!matchHours && !matchMinutes) {
    return null;
  }

  return hoursToMill(hours, minutes);
}

function hoursToMill(hours: number, minutes: number) {
  let hoursToMinutes = hours * 60;
  return (hoursToMinutes + minutes) * 60000;
}
