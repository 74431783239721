import { Component, OnInit } from '@angular/core';
import { ClickupService } from '../services/clickup.service';
import { Observable, of, timer } from 'rxjs';
import { ToastSettings } from '../timesheets/components/table/table.component';
import { AuthService } from '../auth/services/auth.service';
import { User } from '../models/user.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-home',
  template: `
    <app-header
      [username]="username"
      [users]="users$ | async"
      (logout)="logout()"
    ></app-header>
    @if (!userLoaded) {
      <div class="flex justify-center items-center h-80 max-h-screen">
        <div>
          <app-spinner
            class="text-gray-900"
            width="50px"
            height="50px"
            [animated]="true"
          ></app-spinner>
        </div>
      </div>
    }
    <section class="pt-8 px-8">
      @if (userLoaded) {
        <app-table (setToast)="onSetToast($event)"></app-table>
      }
    </section>
    @if (toastVisible) {
      <div
        class="flex justify-center fixed z-50 w-100 left-0 right-0 bottom-0 py-5"
      >
        <app-toast
          message="{{ toastMessage }}"
          [isError]="toastIsError"
        ></app-toast>
      </div>
    }
  `,
})
export class HomeComponent implements OnInit {
  username: string | null = null;
  userEmail: string | undefined;
  users$: Observable<User[]> = of([]);
  userLoaded = false;

  toastIsError: boolean = false;
  toastVisible: boolean = false;
  toastMessage: string = '';

  constructor(
    private authService: AuthService,
    private clickupService: ClickupService,
  ) {
    this.users$ = this.clickupService.getUsers();
    this.clickupService.getUserInfo().subscribe(() => {
      this.userLoaded = true;
    });
    this.clickupService.currentUser$
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.username = user?.username ?? null;
      });
  }

  ngOnInit() {
    this.showToast({ message: 'The entire view will be updated soon' });
  }

  onSetToast(settings: ToastSettings) {
    timer(0).subscribe(() => this.showToast(settings));
  }

  showToast(settings: ToastSettings) {
    if (settings.message) {
      this.toastMessage = settings.message;
    }
    this.toastIsError = settings?.isError ?? false;
    this.toastVisible = settings.show ?? true;
    if (settings.timeout) {
      timer(settings.timeout ?? 3000).subscribe(() => {
        this.toastVisible = false;
      });
    }
  }

  logout() {
    this.authService.signOut().subscribe();
  }
}
