import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      class="px-4 py-2 rounded-full hover:bg-gray-900 hover:text-white {{
        classes
      }}"
      [ngClass]="{
        'bg-primary': isPrimary,
        'bg-purple': isPurple,
        'bg-orange': isOrange,
        'bg-lime': isLime,
        'bg-strawberry': isStrawberry,
        'bg-light-blue': isLightBlue,
        'bg-teal': teal,
        'bg-error': error,
        'bg-gray-400': disabled,
        'bg-success': success,
        'bg-white': white,
        'text-white': disabled,
        'hover:bg-gray-400': disabled,
        'cursor-not-allowed': disabled
      }"
      [disabled]="disabled"
      [type]="type"
    >
      <ng-content> </ng-content>
    </button>
  `,
})
export class ButtonComponent {
  @Input() isPrimary?: boolean;
  @Input() isPurple?: boolean;
  @Input() isOrange?: boolean;
  @Input() isLime?: boolean;
  @Input() isStrawberry?: boolean;
  @Input() isLightBlue?: boolean;
  @Input() teal?: boolean;
  @Input() error?: boolean;
  @Input() disabled?: boolean;
  @Input() success?: boolean;
  @Input() white?: boolean;
  @Input() classes = '';
  @Input() type: 'button' | 'submit' = 'button';
}
