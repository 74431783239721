import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-auth',
  template: ` <div class="flex justify-center items-center h-screen">
    @if (error) {
      <div>
        <h1>Authentication Error</h1>
        <h4>Error code: {{ error }}</h4>
      </div>
    } @else {
      <h1>Authenticating...</h1>
    }
  </div>`,
})
export class AuthComponent {
  unsubscribe$ = new Subject<void>();
  error = '';

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.authLogs$
      .pipe(takeUntilDestroyed())
      .subscribe(({ event, data }) => {
        if (event === 'customState_failure') {
          const domains = decodeURIComponent(data?.message).split('=')[1];
          this.error = data?.message;
          return;
        }

        if (event === 'signIn') {
          this.router.navigate(['/']);
          return;
        }

        this.error = data?.message;
      });
  }
}
