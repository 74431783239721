import { LocalStorage } from 'ttl-localstorage';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {
  set<T>(key: string, value: T, time?: number) {
    LocalStorage.put(key, value, time);
  }

  get(key: string) {
    return LocalStorage.get(key);
  }

  remove(key: string) {
    LocalStorage.removeKey(key);
  }

  contains(key: string) {
    return LocalStorage.keyExists(key);
  }

  runGarbageCollector() {
    LocalStorage.runGarbageCollector();
  }
}
