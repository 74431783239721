import { Injectable } from '@angular/core';
import { PersistenceService } from './persistence.service';
import { concat, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
const WEEK_IN_SECONDS = 7 * DAY_IN_SECONDS;
export const CACHE_DURATION = 2 * WEEK_IN_SECONDS;

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  constructor(
    private persistence: PersistenceService,
    private http: HttpClient,
  ) {
    this.persistence.runGarbageCollector();
  }

  get$<T>(url: string) {
    const key = this.getCacheKey(url);
    if (this.persistence.contains(key)) {
      return of(this.persistence.get(key) as T);
    } else {
      return of();
    }
  }

  set<T>(url: string, result: T) {
    const key = this.getCacheKey(url);
    this.persistence.set(key, result, CACHE_DURATION);
  }

  getCacheKey(url: string) {
    return '#ENDPOINT_CACHE_KEY#' + url;
  }

  httpRequest<T>(endpoint: string) {
    return concat(this.get$<T>(endpoint), this.http.get<T>(endpoint)).pipe(
      tap((result) => {
        this.set(endpoint, result);
      }),
    );
  }
}
