import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Output,
  ViewChild
} from '@angular/core';
import { FavouriteTask } from 'src/app/models/favourite-task.interface';
import { ClickupService } from 'src/app/services/clickup.service';
import {
  DEFAULT_DATE_FORMAT,
  FormattedDate,
  formatDate,
} from 'src/app/utilities/format-date';
import { monthMapping } from 'src/app/utilities/month-mapping';
import {filter, map} from 'rxjs';
import {
  parse,
  isToday,
  addDays,
  startOfWeek,
  endOfWeek,
  subDays, sub, differenceInDays,
} from 'date-fns';
import { PersistenceService } from 'src/app/services/persistence.service';
import { TimeToBeTracked } from 'src/app/timesheets/components/time-tracker/time-tracker.component';
import { WeekSelected } from 'src/app/commons/components/date-picker/date-picker.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbsenceDTO } from 'src/app/models/absence.interface';
import { AbsenceModalFormComponent } from '../absence-modal-form/absence-modal-form.component';
import { AbsenceSuccessModalComponent } from '../absence-success-modal/absence-success-modal.component';
import {TableStoreService} from "../../services/table-store.service";
import {AbsencesCalculatorService} from "../../services/absences-calculator.service";
import {TableRow} from "../../../models/table-row";
import hotkeys from "hotkeys-js";

const KEY = '#ENDPOINT_CACHE_KEY_FAV#';

export type ToastSettings = {
  show?: boolean;
  message?: string;
  isError?: boolean;
  timeout?: number;
};

export type TotalTimeTracked = {
  total: number;
  timeConverted: string;
};

@Component({
  selector: 'app-table',
  template: `
    <section class="py-8">
      <app-absence-success-modal #absenceSuccessModal></app-absence-success-modal>
      <app-absence-modal-form #absenceModal (submitted)="onAbsenceSubmitted($event)"></app-absence-modal-form>

      <div class="flex justify-between items-center flex-wrap gap-y-5">
        <app-date-picker
          #datePicker
          ngDefaultControl
          [(ngModel)]="currentDate"
          (dateRange)="onDateChanged($event)"
          mode="week"
        ></app-date-picker>
        @if (isAdmin) {
          <app-button (click)="exportToXlsx()" [isPrimary]="true">
            <p class="font-bold">Download report for {{ monthSelected() }}</p>
          </app-button>
        }
        <app-switch
          aria-label="Filter by favourites"
          ngDefaultControl
          [ngModel]="filterFavouriteTasks"
          (change)="onFavouriteTasksFilterChanged($event)"
        ></app-switch>
      </div>
    </section>
    @if (loading) {
      <app-skeleton></app-skeleton>
    }
    <div></div>
    <div class="overflow-x-auto lg:overflow-x-visible">
      @if (!loading) {
        <table class="table-auto w-full min-w-[1024px]">
          <ng-container></ng-container>
          <thead class="sticky z-[1] top-0 bg-gray-200 drop-shadow">
            <tr class="shadow-sm">
              <th class="text-left w-auto">
                <input
                  id="searchInput"
                  customAutofocus
                  type="text"
                  placeholder="Search by task name"
                  [(ngModel)]="tasksSearchTerm"
                  class="max-w-48 h-10 border-b border-gray-400 focus:border-gray-500 focus:outline-none text-left font-medium ml-4 bg-gray-200"
                  appIgnoreEscapeValue
                  focusout
                  (ngModelChange)="onSearch()"
                />
              </th>
              @for (date of dates; track date; let i = $index) {
                <th
                  class="text-center align-middle py-8 px-2 w-[8rem]"
                  [ngClass]="{ 'bg-primary': isToday(date) }"
                >
                  {{ date }}
                </th>
              }
              <th><p>TOT</p></th>
              <th>Budget</th>
            </tr>
            <tr app-daily-totals-row [totals]="(totalTrackedTimeByDay$ | async) || []"></tr>
            <tr app-absences-row [absences]="(absences$ | async) || {}" [dates]="dates" (addAbsence)="absenceModal.open()"></tr>
          </thead>
          <tbody>
            @for (row of (tableRows$ | async); track rowTrackFn) {
              <tr
                app-table-row
                [hidden]="
                    filterFavouriteTasks && !row.isFavorite
                  "
                [row]="row"
                (trackTime)="onReceiveTrackingData($event)"
                (toggleFavourite)="toggleFavourite($event)"
              ></tr>
            }
          </tbody>
        </table>
      }
    </div>
  `,
})
export class TableComponent implements AfterViewInit{
  @Output() setToast = new EventEmitter<ToastSettings>();

  private absencesCalculator = inject(AbsencesCalculatorService);
  private clickupService = inject(ClickupService);
  private persistence = inject(PersistenceService);
  private tableStoreService = inject(TableStoreService);

  tasksSearchTerm: string = '';

  currentDate = new Date();

  currentWeekStart!: Date;
  currentWeekEnd!: Date;
  dates: FormattedDate[];
  loading: boolean = true;
  filterFavouriteTasks: boolean = false;

  absences$ = this.tableStoreService.absences$;
  tableRows$ = this.tableStoreService.filteredRows$;
  totalTrackedTimeByDay$ = this.tableStoreService.totalTrackedTimeByDay$;

  @ViewChild('absenceModal') absenceModal!: AbsenceModalFormComponent;
  @ViewChild('absenceSuccessModal') absenceSuccessModal!: AbsenceSuccessModalComponent;
  @ViewChild('searchInput', { static: false }) searchInput!: HTMLInputElement;

  constructor() {
    this.dates = [];
    this.clickupService.currentUser$
      .pipe(
        takeUntilDestroyed(),
        filter((user) => !!user),
      )
      .subscribe((user) => {
        this.onDateChanged({
          startDate: startOfWeek(new Date()),
          endDate: subDays(endOfWeek(new Date()), 2),
        });
      });
  }

  ngAfterViewInit() {
    hotkeys('cmd+k,shift+7,cmd+f', (event) => {
      document.getElementById('searchInput')?.focus();
      event.stopImmediatePropagation();
    });
  }

  get isAdmin() {
    return this.role < 3;
  }

  rowTrackFn(index: number, row: TableRow) {
    return JSON.stringify(row);
  }

  isToday(date: string): boolean {
    return isToday(parse(date, DEFAULT_DATE_FORMAT, new Date()));
  }

  monthSelected(calculateMonthNumber?: boolean): string | number {
    if (this.currentWeekStart) {
      if (calculateMonthNumber) {
        return this.currentWeekStart.getMonth();
      } else {
        return monthMapping[this.currentWeekStart.getMonth()].text;
      }
    }
    return '';
  }

  get role(): number {
    return this.clickupService.currentUser$.value?.role ?? 0;
  }

  get year() {
    if (this.currentWeekStart) {
      return this.currentWeekStart.getFullYear();
    } else return 0;
  }

  exportToXlsx() {
    const month = this.monthSelected(true) as number;
    const year = this.year;
    const firstDay = new Date(year, month, 1).getTime();
    const lastDay = new Date(year, month + 1, 0).getTime();
    this.setToast.emit({
      message: 'Exporting xls',
    });
    this.clickupService
      .exportXls(firstDay, lastDay, this.monthSelected() as string)
      .subscribe({
        error: (e) => {
          this.setToast.emit({
            isError: true,
            message: `failed exporting: ${e}`,
          });
          throw e;
        },
        complete: () => {
          this.setToast.emit({ show: false });
        },
      });
  }

  onDateChanged({ startDate, endDate }: WeekSelected) {
    this.currentWeekStart = new Date(startDate);
    this.currentWeekEnd = new Date(endDate);
    this.dates = this.getWeekDates(this.currentWeekStart, this.currentWeekEnd);
    this.tableStoreService.datesSubject.next(this.dates);

    this.setToast.emit({ message: 'The entire view will be updated soon' });

    if (this.persistence.contains(KEY)) {
      this.filterFavouriteTasks = this.persistence.get(KEY);
    }

    this.loadTasks({ startDate, endDate });
    this.loadTimeEntries({ startDate, endDate });
    this.loadAbsences({ startDate, endDate });
    this.loadFavouriteTasks();
  }

  private loadTasks({ startDate, endDate }: WeekSelected) {
    this.loading = true;

    this.clickupService.getTasks(startDate.getTime(), endDate.getTime()).subscribe({
      next: (tasks) => {
        this.tableStoreService.tasksSubject.next(tasks);
        this.loading = false;
      },
      error: e => {
        this.setToast.emit({
          isError: true,
          message: `error: ${e.message}`,
        });
      },
      complete: () => this.setToast.emit({ show: false }),
    });
  }

  private loadTimeEntries({ startDate, endDate }: WeekSelected) {
    const weekDates = this.getWeekDates(startDate, endDate);

    this.clickupService.getTimeClickUp(startDate.getTime(), endDate.getTime()).subscribe({
      next: (timeEntries) => {
        this.tableStoreService.timeEntriesSubject.next(timeEntries);
      },
      error: e => {
        this.setToast.emit({
          isError: true,
          message: `error: ${e.message}`,
        });
      },
      complete: () => this.setToast.emit({ show: false }),
    })
  }

  private loadFavouriteTasks() {
    this.clickupService.getFavouriteTasks().subscribe({
      next: (favourites) => this.tableStoreService.favouriteTasksSubject.next(favourites),
    });
  }

  private getWeekDates(startOfWeek: Date, endOfWeek: Date) {
    return Array(differenceInDays(endOfWeek, startOfWeek) + 1).fill(null).map((_, i) => {
      return formatDate(addDays(startOfWeek, i));
    });
  }

  onReceiveTrackingData(data: TimeToBeTracked) {
    if (data.trackedTime === null) {
      this.setToast.emit({
        isError: true,
        message: `The format used is not accepted`,
      });
      return;
    }

    const dayToTrack = addDays(this.currentWeekStart, data.trackedDay - 1);
    dayToTrack.setHours(9);
    dayToTrack.setMinutes(0);

    if (this.tableStoreService.isTimeTrackAlreadyPresent(data, dayToTrack)) {
      return;
    }

    this.tableStoreService.trackTime(data, dayToTrack);

    this.setToast.emit({
      message: 'Tracking hours',
    });

    this.clickupService
      .trackTime(data.trackedTime, data.taskId, dayToTrack.getTime())
      .subscribe({
        error: (e) => {
          this.setToast.emit({
            isError: true,
            message: `Failed tracking hours: ${e}`,
          });
        },
        complete: () => {
          this.setToast.emit({
            show: false,
          });
        },
      });
  }

  onAbsenceSubmitted(absence: AbsenceDTO) {
    this.clickupService.createAbsence(absence).subscribe({
      next: (result) => {
        this.absenceModal.close();
        this.absenceSuccessModal.open({...absence, id: result.id });
        this.loadAbsences({ startDate: this.currentWeekStart, endDate: this.currentWeekEnd });
      },
      error: (e) => {
        this.setToast.emit({
          isError: true,
          message: `Failed saving absence: ${e}`,
        });
      },
    });
  }

  toggleFavourite(favourite: FavouriteTask) {
    this.tableStoreService.toggleFavoriteTask(favourite);
    this.trackFavouriteTasks(favourite);
  }

  trackFavouriteTasks(favourite: FavouriteTask) {
    this.clickupService.trackFavouriteTasks(favourite).subscribe({
      error: (e) => {
        this.setToast.emit({
          message: `failed saving favourite: ${e}`,
          isError: true,
        });
      },
    });
  }

  onFavouriteTasksFilterChanged(event: any) {
    this.filterFavouriteTasks = event.target.checked;
    this.persistence.set(KEY, this.filterFavouriteTasks);
  }

  private loadAbsences({ startDate, endDate }: WeekSelected) {
    this.getAbsences({ startDate, endDate }, this.dates).subscribe((absences) => {
      this.tableStoreService.absencesSubject.next(absences);
    });
  }

  private getAbsences({ startDate, endDate }: WeekSelected, dates: FormattedDate[]) {
    const startDay = subDays(startDate, 14).getTime();
    const endDay = addDays(endDate, 14).getTime();
    return this.clickupService.getAbsences(startDay, endDay).pipe(
      map((absences) => {
        return this.absencesCalculator.computeAbsencesPerDay(
          absences,
          dates,
        );
      })
    );
  }

  onSearch() {
    this.tableStoreService.taskSearchTermSubject.next(this.tasksSearchTerm);
  }
}
