import { Component, EventEmitter, Input, Output } from '@angular/core';
import { convertMillisecondsToHoursMinutes } from 'src/app/utilities/convert-time';
import { FormattedDate } from 'src/app/utilities/format-date';

@Component({
  selector: '[app-absences-row]',
  template: `
    <th class="text-left align-middle p-4 flex justify-between items-center">
      <p>ASSENZE:</p>
      <app-button (click)="addAbsence.emit()" class="px-1 text-xs" [isPrimary]="true">+</app-button>
    </th>
    @for (date of dates; track date; let i = $index) {
      @if (absences[date]) {
        <th class="text-orange">
          <p>{{ formatTime(absences[date]) }}</p>
        </th>
      }
      @if (!absences[date]) {
        <th>-</th>
      }
    }
    <th>-</th>
    <th>-</th>
  `,
})
export class AbsencesRowComponent {
  @Input() absences: { [key: string]: number } = {};
  @Input() dates: FormattedDate[] = [];
  @Output() addAbsence = new EventEmitter<void>();

  formatTime(time: number) {
    return convertMillisecondsToHoursMinutes(time * 3600_000);
  }
}
