import { Injectable } from '@angular/core';
import { addDays, endOfDay, format, parse, startOfDay } from 'date-fns';
import {
  DEFAULT_DATE_FORMAT,
  FormattedDate,
  formatDate,
} from 'src/app/utilities/format-date';

const MAX_ABSENCE_HOURS_PER_DAY = 8;
const DECIMAL_DIGITS = 2;
const ONE_HOUR_IN_MS = 1000 * 60 * 60;

type AbsenceMap = Record<FormattedDate, number>;

type TimeInterval = {
  start_date: string | number;
  due_date: string | number;
};

function toPrecision(value: number, precision: number) {
  return +value.toFixed(precision);
}

class Absence {
  startDate: AbsenceDate;
  dueDate: AbsenceDate;
  timeInterval: TimeInterval;

  constructor(interval: TimeInterval) {
    this.timeInterval = interval;
    this.startDate = new AbsenceDate(+interval.start_date);
    this.dueDate = new AbsenceDate(+interval.due_date);
  }

  get hasStartDate() {
    return Boolean(this.timeInterval.start_date);
  }

  get hasAbsenceDueDate(): boolean {
    return Boolean(this.timeInterval.due_date);
  }

  get durationInHours() {
    return (
      toPrecision(
        this.dueDate.getTime() - this.startDate.getTime(),
        DECIMAL_DIGITS,
      ) / ONE_HOUR_IN_MS
    );
  }

  isBetween(day: Date) {
    return (
      startOfDay(this.startDate.date) <= day &&
      endOfDay(this.dueDate.date) > day
    );
  }

  isWithin(day: Date) {
    return (
      this.startDate.date >= startOfDay(day) &&
      this.dueDate.date <= endOfDay(day)
    );
  }

  isMultipleDays() {
    return this.startDate.toString() !== this.dueDate.toString();
  }

  isEndingAt(day: FormattedDate) {
    return day === this.dueDate.toString();
  }

  isFullDay() {
    const isSameDate = this.startDate.equals(this.dueDate);
    const isFourOClock =
      format(this.startDate.date, 'HH:mm:ss.SSSS') === '04:00:00.0000';
    return isSameDate && isFourOClock;
  }
}

class AbsenceMapBuilder {
  value: AbsenceMap = {};

  markFullDayAbsence(day: FormattedDate) {
    this.markAbsence(day, MAX_ABSENCE_HOURS_PER_DAY);
  }

  markAbsence(day: FormattedDate, hours: number) {
    const actualAbsenceHours = this.value[day] ?? 0;
    this.value[day] = Math.min(
      actualAbsenceHours + hours,
      MAX_ABSENCE_HOURS_PER_DAY,
    );
  }

  markAbsencesForDay(absence: Absence, day: AbsenceDate) {
    if (!absence.hasAbsenceDueDate) {
      return;
    }

    if (this.markAbsenceWhenFullDay(absence, day)) {
      return;
    }

    if (this.markAbsenceWhenBetweenMultipleDays(absence, day)) {
      return;
    }

    if (this.markAbsenceOnStartAndEndDateEquals(absence, day)) {
      return;
    }

    this.markAbsenceWhenPartialDay(absence, day);
  }

  markAbsenceOnStartAndEndDateEquals(absence: Absence, day: AbsenceDate) {
    if (!absence.isFullDay()) {
      return false;
    }

    if (absence.isEndingAt(day.toString())) {
      this.markFullDayAbsence(day.toString());
    }

    return true;
  }

  markAbsenceWhenFullDay(absence: Absence, day: AbsenceDate) {
    if (absence.hasStartDate) {
      return false;
    }

    if (absence.isEndingAt(day.toString())) {
      this.markFullDayAbsence(day.toString());
    }

    return true;
  }

  markAbsenceWhenBetweenMultipleDays(absence: Absence, day: AbsenceDate) {
    if (!absence.isMultipleDays()) {
      return false;
    }

    if (absence.isBetween(day.date)) {
      this.markFullDayAbsence(day.toString());
    }

    return true;
  }

  markAbsenceWhenPartialDay(absence: Absence, day: AbsenceDate) {
    if (!absence.isWithin(day.date)) {
      return false;
    }

    this.markAbsence(day.toString(), absence.durationInHours);

    return true;
  }
}

class AbsenceDate {
  readonly date: Date;
  readonly dateString: FormattedDate;

  constructor(anyDateFormat: FormattedDate | number | Date) {
    if (anyDateFormat instanceof Date) {
      this.date = anyDateFormat;
      this.dateString = formatDate(anyDateFormat);
    } else if (typeof anyDateFormat === 'number') {
      this.date = new Date(anyDateFormat);
      this.dateString = formatDate(this.date);
    } else {
      this.date = parse(anyDateFormat, DEFAULT_DATE_FORMAT, new Date());
      this.dateString = anyDateFormat;
    }
  }

  toString() {
    return this.dateString;
  }

  getTime() {
    return this.date.getTime();
  }

  equals(date: AbsenceDate) {
    return this.date.getTime() === date.getTime();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AbsencesCalculatorService {
  constructor() {}

  computeAbsencesPerDay(
    absences: TimeInterval[],
    dates: FormattedDate[],
  ): AbsenceMap {
    const absenceBuilder = new AbsenceMapBuilder();
    for (const timeInterval of absences) {
      const absence = new Absence(timeInterval);
      for (const date of dates) {
        absenceBuilder.markAbsencesForDay(absence, new AbsenceDate(date));
      }
    }

    return absenceBuilder.value;
  }
}
