import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toast',
  template: `
    <div
      class="w-fit h-fit rounded-md py-2 px-2 text-center text-white flex gap-2"
      [ngClass]="{
        'bg-error': isError,
        'bg-success': !isError
      }"
    >
      @if (!isError) {
        <app-spinner [animated]="true"></app-spinner>
      }
      <p>{{ message }}</p>
    </div>
  `,
})
export class ToastComponent {
  @Input() isError!: boolean;
  @Input() message: string = '';
}
