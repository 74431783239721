import { Directive, ElementRef, OnInit } from '@angular/core';
import { timer } from 'rxjs';

@Directive({
  selector: '[customAutofocus]',
})
export class AutoFocusDirective implements OnInit {
  constructor(public elementRef: ElementRef) {}

  ngOnInit() {
    timer(0).subscribe(() => {
      this.elementRef.nativeElement.focus();
    });
  }
}
