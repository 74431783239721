import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { add, format, set } from 'date-fns';
import { ModalComponent } from 'src/app/commons/components/modal/modal.component';
import { AbsenceDTO } from 'src/app/models/absence.interface';

@Component({
  selector: 'app-absence-modal-form',
  template: `
    <form [formGroup]="form" (submit)="$event.preventDefault(); submit()">
      <app-modal #modal>
        <div body>
          <div class="py-1">
            <label class="block">Title</label>
            <input #firstInput autofocus placeholder="Absence title" class="border p-2 bg-white rounded border-gray-300 w-full" formControlName="title" required />

            <span class="text-error text-sm" [ngClass]="{ 'opacity-0' : !isValidating }">{{ hasError('title') ? 'Title is required' : "&nbsp;" }}</span>
          </div>

          <div class="py-1">
            <label class="block">Starts At</label>
            <input class="border p-2 bg-white rounded border-gray-300 w-full" type="datetime-local" formControlName="startsAt" />

            <span class="text-error text-sm" [ngClass]="{ 'opacity-0' : !isValidating }">{{ hasError('startsAt') ? 'A date is required' : "&nbsp;" }}</span>
          </div>

          <div class="py-1">
            <label class="block">Ends At</label>
            <input class="border p-2 bg-white rounded border-gray-300 w-full" type="datetime-local" formControlName="endsAt" />

            <span class="text-error text-sm" [ngClass]="{ 'opacity-0' : !isValidating }">{{ hasError('endsAt') ? 'A date is required' : "&nbsp;" }}</span>
          </div>

          <div class="py-1">
            <label class="block">Type</label>
            <select class="border p-2 bg-white rounded border-gray-300 w-full" formControlName="type">
              <option value="vacation">Vacation / Permission</option>
              <option value="flexibility">Flexibility</option>
            </select>

            <span class="text-error text-sm" [ngClass]="{ 'opacity-0' : !isValidating }">{{ hasError('type') ? 'A value is required' : "&nbsp;" }}</span>
          </div>
        </div>

        <div footer class="flex justify-between">
          <app-button type="button" (click)="close()">Close</app-button>
          <app-button [isPrimary]="true" type="submit">Submit</app-button>
        </div>
      </app-modal>
    </form>
  `,
})
export class AbsenceModalFormComponent {
  @Output() submitted = new EventEmitter<AbsenceDTO>();

  form = this.formBuilder.nonNullable.group({
    title: ['', Validators.required],
    startsAt: [this.baseStartsAt, Validators.required],
    endsAt: [this.baseEndsAt, Validators.required],
    type: ['vacation', Validators.required],
  });

  isValidating = false;

  @ViewChild('modal') modal!: ModalComponent;
  @ViewChild('firstInput') firstInput!: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder) {
  }

  get baseStartsAt() {
    const date = add(set(new Date(), { hours: 9, minutes: 0 }), { days: 1});
    return format(date, 'yyyy-MM-dd\'T\'HH:mm');
  }

  get baseEndsAt() {
    const date = add(set(new Date(), { hours: 18, minutes: 0 }), { days: 1 });
    return format(date, 'yyyy-MM-dd\'T\'HH:mm');
  }

  hasError(controlName: string) {
    return this.form.get(controlName)?.errors;
  }

  submit() {
    if (this.form.invalid) {
      this.isValidating = true;
      return;
    }

    const data = this.form.getRawValue();

    this.submitted.emit({
      ...data,
      startsAt: new Date(data.startsAt),
      endsAt: new Date(data.endsAt),
    });
  }

  open() {
    this.modal.open();
    setTimeout(() => {
      this.firstInput.nativeElement.focus();
    }, 100)
  }

  close() {
    this.modal.close();
    this.form.reset();
    this.isValidating = false;
  }
}
