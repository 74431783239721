export const monthMapping: { [key: string]: { text: string } } = {
  0: { text: 'January' },
  1: { text: 'February' },
  2: { text: 'March' },
  3: { text: 'April' },
  4: { text: 'May' },
  5: { text: 'June' },
  6: { text: 'July' },
  7: { text: 'August' },
  8: { text: 'September' },
  9: { text: 'October' },
  10: { text: 'November' },
  11: { text: 'December' },
};
