import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  template: `
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        class="sr-only peer"
        [ngModel]="ngModel"
        (change)="change.emit($event)"
      />
      <div
        class="w-11 h-6 rounded-full bg-gray-400 peer-checked:after:translate-x-full after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"
      ></div>
      <span class="ml-3 text-sm font-medium"
        ><p>Favourites only</p></span
      >
    </label>
  `,
})
export class SwitchComponent {
  @Input() ngModel = false;
  @Output() change = new EventEmitter<any>();
}
