import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { timer } from 'rxjs';

@Component({
  selector: 'app-modal',
  template: `
    <div
      class="modal py-5 overflow-auto fixed top-0 bottom-0 left-0 right-0 w-fz bg-gray-800 bg-opacity-50 z-[1000]"
      [style.display]="isVisible ? 'flex' : 'none'"
    >
      <div role="dialog" class="modal-container m-auto" (appClickOutside)="isVisible && closeOnClickOutside && close()">
        <div
          class="modal-content relative py-4 px-6 bg-white border-gray-300 border rounded z-50"
          [ngClass]="sizeClass"
        >
          <div class="modal-header mb-4" *ngIf="showHeader">
            <ng-content select="[header]"></ng-content>
          </div>
          <div class="modal-separator-line mb-2"></div>

          <div class="modal-body pb-4 overflow-y-auto max-h-[500px]">
            <ng-content select="[body]"></ng-content>
          </div>

          <div class="modal-separator-line mt-2"></div>
          <div class="modal-footer pb-4">
            <ng-content select="[footer]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep {
        .modal-separator-line {
          border-top: 1px solid var(--color-gray-300);
          margin-left: -1.5rem;
          margin-right: -1.5rem;
        }

        body.modal-open {
          overflow: hidden;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() appendToBody = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() showHeader: boolean = true;
  @Input() closeOnClickOutside: boolean = true;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  private element: HTMLElement;
  isVisible = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.appendToBody) {
      document.body.appendChild(this.element);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownEscape() {
    this.close();
  }

  ngOnDestroy(): void {
    this.element.remove();
  }

  open(): void {
    timer(0).subscribe(() => {
      this.renderer.addClass(document.body, 'modal-open');
      this.isVisible = true;
      this.opened.emit();
      this.cd.detectChanges();
    })
  }

  close(): void {
    this.closed.emit();
    this.isVisible = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.cd.detectChanges();
  }

  get sizeClass() {
    if (this.size === 'sm') {
      return 'max-w-[500px] min-w-[350px]';
    }
    if (this.size === 'lg') {
      return 'max-w-[850px] min-w-[750px]';
    }
    return 'max-w-[750px] min-w-[500px]';
  }

  get isModalVisible(): boolean {
    return this.isVisible;
  }
}
