import { Component, Input, EventEmitter, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from 'src/app/models/user.interface';
import { ClickupService } from 'src/app/services/clickup.service';

@Component({
  selector: 'app-header',
  template: `
    <nav
      class="flex justify-between py-8 px-8 rounded-b-3xl shadow-md items-center"
    >
      <div>
        <img class="w-40" src="../../assets/brand-monade/logo.svg" alt="logo" />
      </div>
      <div class="flex gap-4">
        <div class="self-center">
          <h6 class="whitespace-nowrap text-ellipsis pl-4">
            {{ username }}
          </h6>
        </div>
        @if (isAdmin) {
          <app-admin-users-dropdown
            [users]="internalUsers"
            (selected)="onSelectedUser($event)"
          ></app-admin-users-dropdown>
        }
        <div class="self-center">
          <app-button
            (click)="onLogOut()"
            [isPrimary]="true"
            [disabled]="false"
            classes="!px-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
              />
            </svg>
          </app-button>
        </div>
      </div>
    </nav>
  `,
})
export class HeaderComponent {
  @Input() username: string | null = null;
  @Input() users: User[] | null = null;
  @Output() logout = new EventEmitter<void>();

  originalUser: User | null = null;

  constructor(private clickupService: ClickupService) {
    this.clickupService.currentUser$
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        if (user && !this.originalUser) {
          this.originalUser = user;
        }
      });
  }

  get isAdmin() {
    return (this.originalUser?.role ?? 999) < 3;
  }

  get internalUsers() {
    return this.users?.filter((u) => u.role < 4) ?? [];
  }

  onSelectedUser(user: User | null) {
    this.clickupService.currentUser$.next(user ?? null);
  }

  onLogOut() {
    this.logout.emit();
  }
}
