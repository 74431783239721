import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-admin-users-dropdown',
  template: `
    <div class="relative" (appClickOutside)="isOpen = false">
      <app-button
        (click)="toggle()"
        [isPrimary]="true"
        [disabled]="false"
        classes="!px-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="setting"
          class="w-6 h-6"
        >
          <path
            fill="currentColor"
            d="M19.9 12.66a1 1 0 0 1 0-1.32l1.28-1.44a1 1 0 0 0 .12-1.17l-2-3.46a1 1 0 0 0-1.07-.48l-1.88.38a1 1 0 0 1-1.15-.66l-.61-1.83a1 1 0 0 0-.95-.68h-4a1 1 0 0 0-1 .68l-.56 1.83a1 1 0 0 1-1.15.66L5 4.79a1 1 0 0 0-1 .48L2 8.73a1 1 0 0 0 .1 1.17l1.27 1.44a1 1 0 0 1 0 1.32L2.1 14.1a1 1 0 0 0-.1 1.17l2 3.46a1 1 0 0 0 1.07.48l1.88-.38a1 1 0 0 1 1.15.66l.61 1.83a1 1 0 0 0 1 .68h4a1 1 0 0 0 .95-.68l.61-1.83a1 1 0 0 1 1.15-.66l1.88.38a1 1 0 0 0 1.07-.48l2-3.46a1 1 0 0 0-.12-1.17ZM18.41 14l.8.9-1.28 2.22-1.18-.24a3 3 0 0 0-3.45 2L12.92 20h-2.56L10 18.86a3 3 0 0 0-3.45-2l-1.18.24-1.3-2.21.8-.9a3 3 0 0 0 0-4l-.8-.9 1.28-2.2 1.18.24a3 3 0 0 0 3.45-2L10.36 4h2.56l.38 1.14a3 3 0 0 0 3.45 2l1.18-.24 1.28 2.22-.8.9a3 3 0 0 0 0 3.98Zm-6.77-6a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"
          ></path>
        </svg>
      </app-button>
      @if (isOpen) {
        <div
          class="absolute z-50 bg-white bottom-0 right-0 translate-y-full border border-gray-300 shadow max-h-[50vh] overflow-auto"
        >
          <div class="sticky top-0">
            <input
              #searchInput
              [(ngModel)]="filter"
              autofocus
              type="text"
              placeholder="Search..."
              class="p-2 w-full border-b border-gray-300"
            />
          </div>
          @for (user of filteredUsers; track user) {
            <div
              class="p-2 cursor-pointer border-b border-gray-300 hover:bg-primary"
              (click)="selected.emit(user); isOpen = false"
            >
              {{ user.email }}
            </div>
          }
        </div>
      }
    </div>
  `,
})
export class AdminUsersDropdownComponent {
  @Input() users: User[] = [];
  @Output() selected = new EventEmitter<User>();

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  filter = '';
  isOpen = false;

  get filteredUsers() {
    return this.users.filter((u) =>
      u.email.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()),
    );
  }

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      }, 0);
    }
  }
}
