import { Injectable } from '@angular/core';
import { TimeEntriesByTaskId } from 'src/app/models/time-entries-by-task-id.interface';
import { convertMillisecondsToHoursMinutes } from 'src/app/utilities/convert-time';

export type TotalTimeTrackedByDay = {
  day: number;
  total: number;
  timeConverted: string;
};

export type TimeEntriesByDay = Record<number, number[]>;

@Injectable({
  providedIn: 'root',
})
export class TimeEntriesCalculatorService {
  constructor() {}

  computeTimeEntriesByDay(timeEntries: TimeEntriesByTaskId[]) {
    const timeEntriesByDay = this.computeRawTimeEntriesByDay(timeEntries);

    const totalTimeTrackedByDay: TotalTimeTrackedByDay[] = [];
    for (const weekDay in timeEntriesByDay) {
      const tasksTimes = timeEntriesByDay[weekDay];

      const total = tasksTimes.reduce((acc, curr) => acc + curr, 0);
      const timeConverted =
        total === 0 ? '0' : convertMillisecondsToHoursMinutes(total);
      totalTimeTrackedByDay.push({
        day: +weekDay,
        total: total,
        timeConverted: timeConverted,
      });
    }
    return totalTimeTrackedByDay;
  }

  computeRawTimeEntriesByDay(
    timeEntries: TimeEntriesByTaskId[],
  ): Record<number, number[]> {
    const timeEntriesByDay: Record<number, number[]> = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
    };
    for (const timeEntriesByTaskId of timeEntries) {
      const dates = Object.keys(timeEntriesByTaskId.time_entries);

      dates.forEach((date) => {
        const time = timeEntriesByTaskId.time_entries[date];
        const weekday = new Date(date).getDay();

        timeEntriesByDay[weekday].push(time!);
      });
    }
    return timeEntriesByDay;
  }

  computeTotals(totalTimeTrackedByDay: TotalTimeTrackedByDay[]): string {
    return convertMillisecondsToHoursMinutes(
      Object.values(totalTimeTrackedByDay).reduce(
        (acc, obj) => acc + obj.total,
        0,
      ),
    );
  }
}
